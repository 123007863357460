import React from 'react';
const { REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID } = process.env;

export default {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-dashboard',
        },
        {
            divider: true,
        },

        {
            name: 'Appointments',
            url: '/appointments',
            icon: 'fa fa-clock-o',
        },
        {
            name: 'Recurring Appointments',
            url: '/appointments-recurring',
            icon: 'fa fa-refresh',
        },
        {
            name: 'Properties',
            url: '/properties',
            icon: 'fa fa-home',
        },
        {
            name: 'Feedback Questions',
            url: '/feedback-questions',
            icon: 'fa fa-question',
        },
        {
            name: 'Reapit',
            icon: 'fa fa-reapit-icon',
            children: [
                {
                    name: 'Properties',
                    url: '/reapit/properties',
                    icon: 'fa fa-home',
                }
            ]
        },
        {
            name: 'Profile',
            icon: 'fa fa-user-o',
            notification_target_type_id: parseInt(
                REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID,
                10
            ),
            children: [
                {
                    name: 'Edit Profile',
                    url: '/me/edit',
                    icon: 'fa fa-pencil',
                },
                {
                    name: 'Address',
                    url: '/me/address',
                    icon: 'fa fa-map-marker',
                },
                {
                    name: 'Change Password',
                    url: '/me/password',
                    icon: 'fa fa-key',
                },
                {
                    name: 'Security Settings',
                    url: '/me/security',
                    icon: 'fa fa-cog',
                },
                {
                    name: 'Third party services',
                    url: '/me/third-party-services',
                    icon: 'fa fa-code-fork',
                },
                {
                    name: 'Phone Pin',
                    url: '/me/phone-pin',
                    icon: 'fa fa-phone',
                },
                {
                    name: 'Notification Settings',
                    url: '/me/notification-settings',
                    icon: 'fa fa-bell',
                },
            ]
        },
        {
            name: 'Documents',
            icon: 'fa fa-files-o',
            children: [
                {
                    name: 'Terms and Conditions',
                    url: '/documents/terms-and-conditions',
                    icon: 'fa fa-file',
                },
                {
                    name: 'Property Inspection Example Reports',
                    url: '/documents/property-investor-reports',
                    icon: 'fa fa-file',
                },
            ]
        },
        {
            name: 'User Management',
            icon: 'fa fa-users',
            children: [
                {
                    name: 'Users',
                    url: '/users',
                    icon: 'fa fa-users',
                },
                {
                    name: 'Groups',
                    url: '/groups',
                    icon: 'fa fa-building',
                },
            ]
        },
        {
            name: 'Viewing Packs',
            url: '/viewing-packs',
            icon: 'fa fa-th',
        },
        {
            name: 'Price List',
            url: '/price-list',
            icon: 'fa fa-gbp',
        },
        {
            name: 'Rate your Viewbers',
            url: '/star-ratings',
            icon: 'fa fa-star',
        }
    ],
};
