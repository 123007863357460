import actions from './actions';
import baseReducer from '../base/reducer';

const initial_state = {
    created: false,
    current_page: 1,
    destroyed: false,
    error: null,
    fetching_resources: false,
    last_fetched: 0,
    resources: [],
    to_read_resources: [],
    total: 0,
    updated: false,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CHANGE_PAGE_RESOURCES:
            return {
                ...state,
                current_page: action.payload.data.page
            };
        case actions.CLEAR_METADATA_RESOURCES: {
            return {
                ...state,
                error: null,
                current_page: 1,
                destroyed: false,
                fetching_to_read_resources: false,
                to_read_resources: [],
            };
        }
        case actions.CLEAR_METADATA_RESOURCE_CREATE:
            return {
                ...state,
                error: null,
                // created: false,
                resource: null,
            };
        case actions.GET_TO_READ_RESOURCES_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_to_read_resources: true,
                to_read_resources: [],
                total: 0,
            };
        }
        case actions.GET_TO_READ_RESOURCES_SUCCESS: {
            const { resources } = action.payload;
            return {
                ...state,
                error: null,
                fetching_to_read_resources: false,
                last_fetched: (new Date()).getTime(),
                to_read_resources: resources,
            };
        }
        case actions.GET_TO_READ_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_to_read_resources: false,
            };
        default:
            return baseReducer(actions)(state, action);
    }
};

export default reducer;
