import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { noInternetConnectionNotification } from './helpers/toastNotification';

// Profile lazies...
const Profile = () => <Redirect to="/me/edit" />;
const NotificationSettings = lazy(() => retry(() => import('./views/Profile/NotificationSettings')));
const PhonePin = lazy(() => retry(() => import('./views/Profile/PhonePin')));
const EditProfile = lazy(() => retry(() => import('./views/Profile/EditProfile')));
const AddressProfile = lazy(() => retry(() => import('./views/Profile/AddressProfile')));
const ChangePasswordProfile = lazy(() => retry(() => import('./views/Profile/ChangePasswordProfile')));
const UserSecuritySettings = lazy(() => retry(() => import('./views/Profile/SecuritySettings')));
const ThirdPartyServices = lazy(() => retry(() => import('./views/Profile/ThirdPartyServices/Index')));
const ThirdPartyServicesConnectReapit = lazy(() => retry(() => import('./views/Profile/ThirdPartyServices/ConnectReapit')));

// Clients lazies...
const Clients = lazy(() => retry(() => import('./views/Resources/Clients/List')));
const ClientCreate = lazy(() => retry(() => import('./views/Resources/Clients/Create')));
const ClientEdit = lazy(() => retry(() => import('./views/Resources/Clients/Edit')));

// Dashboard lazies...
const Dashboard = lazy(() => retry(() => import('./views/Dashboard')));

// Viewing lazies...
const Viewings = lazy(() => retry(() => import('./views/Resources/Viewings/List')));
const ViewingCreate = lazy(() => retry(() => import('./views/Resources/Viewings/Create')));
const ViewingEdit = lazy(() => retry(() => import('./views/Resources/Viewings/Edit')));

const ViewingDownload = lazy(() => retry(() => import('./views/Resources/Viewings/Download')));
const ViewingCalendar = lazy(() => retry(() => import('./views/Resources/Viewings/Calendar')));
const ViewingFeedback = lazy(() => retry(() => import('./views/Resources/Viewings/Feedback')));

const ViewingsRecurring = lazy(() => retry(() => import('./views/Resources/RecurringViewings/List')));
const ViewingsRecurringEdit = lazy(() => retry(() => import('./views/Resources/RecurringViewings/Edit')));
const ViewingsRecurringRecurrences = lazy(() => retry(() => import('./views/Resources/RecurringViewings/Edit/Tabs/RecurrenceHistoryTab')));

// Properties lazies...
const Properties = lazy(() => retry(() => import('./views/Resources/Properties/List')));
const PropertyCreate = lazy(() => retry(() => import('./views/Resources/Properties/Create')));
const PropertyShow = lazy(() => retry(() => import('./views/Resources/Properties/Show')));
const PropertyImport = lazy(() => retry(() => import('./views/Resources/Properties/Import')));
const PropertyKeyHolderCalendar = lazy(() => retry(() => import('./views/Resources/Properties/KeyHolderCalendar')));

// Order lazies...
const OrderShow = lazy(() => retry(() => import('./views/Resources/Orders/Show')));

// Document Categories lazies...
const TermsAndConditions = lazy(() => retry(() => import('./views/Resources/Documents/TermsAndConditions')));
const PropertyInvestorReports = lazy(() => retry(() => import('./views/Resources/Documents/PropertyInvestorReports')));

// Feedback Questions lazies...
const FeedbackQuestions = lazy(() => retry(() => import('./views/Resources/FeedbackQuestions/List')));
const FeedbackQuestionsCreate = lazy(() => retry(() => import('./views/Resources/FeedbackQuestions/Create')));
const FeedbackQuestionsEdit = lazy(() => retry(() => import('./views/Resources/FeedbackQuestions/Edit')));

// Price list lazies...
const PriceList = lazy(() => retry(() => import('./views/Pages/PriceList')));

// Reapit lazies...
const ReapitPropertiesList = lazy(() => retry(() => import('./views/Resources/Reapit/Properties/List')));

// Star Ratings lazies...
const AppointmentsRatingsList = lazy(() => retry(() => import('./views/Resources/Appointments/RatingsList')));
const AppointmentsRate = lazy(() => retry(() => import('./views/Resources/Appointments/Rate')));

// User Management lazies...
const Users = lazy(() => retry(() => import('./views/Resources/Users/List')));
const UserCreate = lazy(() => retry(() => import('./views/Resources/Users/Create')));
const UserEdit = lazy(() => retry(() => import('./views/Resources/Users/Edit')));

// User Management lazies...
const UserActions = lazy(() => retry(() => import('./views/Resources/UserActions/List')));
const UserActionsEdit = lazy(() => retry(() => import('./views/Resources/UserActions/Edit')));

// Viewing Pack lazies...
const ViewingPacks = lazy(() => retry(() => import('./views/Resources/ViewingPacks/List')));
const ViewingPacksAvailable = lazy(() => retry(() => import('./views/Resources/ViewingPacksAvailable/List')));
const ViewingPacksAvailableCreate = lazy(() => retry(() => import('./views/Resources/ViewingPacksAvailable/Create')));

const TrustpilotRatingUpdate = lazy(() => retry(() => import('./views/Pages/TrustpilotRatingUpdate.tsx')));

const FeedbackImage = lazy(() => retry(() => import('./views/Pages/FeedbackImage.tsx')));

const FeedbackPropertyAnswer = lazy(() => retry(() => import('./views/Pages/FeedbackPropertyAnswer.tsx')));

// Routes...
const routes = [
    { path: '/', exact: true, name: 'Home', component: Dashboard },
    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/appointments/download', exact: true, name: 'Appointments Download', component: ViewingDownload },
    { path: '/appointments/calendar', exact: true, name: 'Appointments Calendar', component: ViewingCalendar },
    { path: '/appointments/:id', exact: true, name: 'Appointment Details', component: ViewingEdit },
    { path: '/appointments/:id/feedback', exact: true, name: 'Feedback', component: ViewingFeedback },
    { path: '/appointments', exact: true, name: 'Appointments', component: Viewings },
    { path: '/appointments-recurring/:id', exact: true, name: 'Recurring Appointment', component: ViewingsRecurringEdit },
    { path: '/appointments-recurring/:id/recurrences', exact: true, name: 'Recurring Appointment', component: ViewingsRecurringRecurrences },
    { path: '/appointments-recurring', exact: true, name: 'Recurring Appointments', component: ViewingsRecurring },
    { path: '/documents/terms-and-conditions', exact: true, name: 'Terms and Conditions', component: TermsAndConditions },
    { path: '/documents/property-investor-reports', exact: true, name: 'Property Investor Reports', component: PropertyInvestorReports },
    { path: '/feedback-questions/create', exact: true, name: 'Create Feedback Question', component: FeedbackQuestionsCreate },
    { path: '/feedback-questions/:id', exact: true, name: 'Feedback Question Edit', component: FeedbackQuestionsEdit },
    { path: '/feedback-questions', exact: true, name: 'Feedback Questions', component: FeedbackQuestions },
    { path: '/groups', exact: true, name: 'Groups', component: Clients },
    { path: '/groups/create', exact: true, name: 'Create Group', component: ClientCreate },
    { path: '/groups/:id', exact: true, name: 'Group Details', component: ClientEdit },
    { path: '/properties/:id/appointments/create', exact: true, name: 'Book Appointment', component: ViewingCreate },
    { path: '/properties/:id/key-holder-calendar', exact: true, name: 'Key-Holder Calendar', component: PropertyKeyHolderCalendar },
    { path: '/properties/:id/key-holder-calendar/:Secondary', exact: true, name: 'Secondary Key-Holder Calendar', component: PropertyKeyHolderCalendar },
    { path: '/properties/import', exact: true, name: 'Import Property from BLM', component: PropertyImport },
    { path: '/properties/create', exact: true, name: 'Create Property', component: PropertyCreate },
    { path: '/properties/:id', exact: true, name: 'Property Details', component: PropertyShow },
    { path: '/properties', exact: true, name: 'Properties', component: Properties },
    { path: '/orders/:id', exact: true, name: 'Order', component: OrderShow },
    { path: '/price-list', exact: true, name: 'Price List', component: PriceList },
    { path: '/reapit/properties', exact: true, name: 'Reapit Properties', component: ReapitPropertiesList },
    { path: '/me/phone-pin', exact: true, name: 'Phone Pin', component: PhonePin },
    { path: '/me/notification-settings', exact: true, name: 'Notification Settings', component: NotificationSettings },
    { path: '/me/address', exact: true, name: 'Address', component: AddressProfile },
    { path: '/me/edit', exact: true, name: 'Edit Profile', component: EditProfile },
    { path: '/me/password', exact: true, name: 'Change Password', component: ChangePasswordProfile },
    { path: '/me/security', exact: true, name: 'Security Settings', component: UserSecuritySettings },
    { path: '/me/third-party-services', exact: true, name: 'Third Party Services', component: ThirdPartyServices },
    { path: '/me/third-party-services/connect-reapit', exact: true, name: 'Third Party Services | Connect Reapit', component: ThirdPartyServicesConnectReapit },
    { path: '/me', exact: true, name: 'Profile', component: Profile },
    { path: '/star-ratings/:id', exact: true, name: 'Star Ratings', component: AppointmentsRate },
    { path: '/star-ratings', exact: true, name: 'Star Ratings', component: AppointmentsRatingsList },
    { path: '/users/:user_id/actions/:id', exact: true, name: 'User Actions', component: UserActionsEdit },
    { path: '/users/:user_id/actions', exact: true, name: 'User Actions', component: UserActions },
    { path: '/users/create', exact: true, name: 'Create User', component: UserCreate },
    { path: '/users/:id', exact: true, name: 'User Details', component: UserEdit },
    { path: '/users', exact: true, name: 'Users', component: Users },
    { path: '/viewing-packs', exact: true, name: 'Viewing Packs', component: ViewingPacks },
    { path: '/viewing-packs-available/:id', exact: true, name: 'Add this Pack', component: ViewingPacksAvailableCreate },
    { path: '/viewing-packs-available', exact: true, name: 'Viewing Packs Available', component: ViewingPacksAvailable },
    { path: '/trustpilot-rating/:trustpilot_rating_id/update', exact: true, name: 'Trustpilot Rating update', component: TrustpilotRatingUpdate },
    { path: '/feedback-image/:id', exact: true, name: 'Feedback Image', component: FeedbackImage },
    { path: '/feedback-property-answer/:id', exact: true, name: 'Feedback Property Answer', component: FeedbackPropertyAnswer },
];

export default routes;

/**
 * Credits to https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
 */
const retry = (fn, retriesLeft = 5, interval = 1000) => {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch(error => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // maximum retries exceeded
                        noInternetConnectionNotification();
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}
