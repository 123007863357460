import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DefaultLayout } from './containers';
import ErrorBoundary from './ErrorBoundary';
import MaintenanceChecker from './MaintenanceChecker';
import GuestComponent from './components/GuestComponent';
import { Auth, AuthAs, Login, TwoFactorPage, Page404, PasswordReset, PasswordResetEmail, SignInReapit } from './views/Pages';
import './scss/style.scss';

class App extends Component {

    render() {
        const { external_four_zero_four } = this.props;

        return (
            <>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={props => (
                            <ErrorBoundary>
                                <GuestComponent>
                                    <MaintenanceChecker>
                                        <Login {...props} />
                                    </MaintenanceChecker>
                                </GuestComponent>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/2fa" name="2FA Page" render={props => (
                            <ErrorBoundary>
                                <GuestComponent>
                                    <MaintenanceChecker>
                                        <TwoFactorPage {...props} />
                                    </MaintenanceChecker>
                                </GuestComponent>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/signin-reapit" name="Sign In with Reapit" render={props => (
                            <ErrorBoundary>
                                <GuestComponent>
                                    <MaintenanceChecker>
                                        <SignInReapit {...props} />
                                    </MaintenanceChecker>
                                </GuestComponent>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/auth" name="Auth" render={props => (
                            <ErrorBoundary>
                                <MaintenanceChecker>
                                    <Auth {...props} />
                                </MaintenanceChecker>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/auth-as" name="Auth As" render={props => (
                            <ErrorBoundary>
                                <MaintenanceChecker>
                                    <AuthAs {...props} />
                                </MaintenanceChecker>
                            </ErrorBoundary>
                        )} />
                        <Route
                            exact
                            path="/password/reset/:token/:email"
                            name="Password Reset Page"
                            render={props => (
                                <ErrorBoundary>
                                    <GuestComponent>
                                        <MaintenanceChecker>
                                            <PasswordReset {...props} />
                                        </MaintenanceChecker>
                                    </GuestComponent>
                                </ErrorBoundary>
                            )}
                        />
                        <Route
                            exact
                            path="/password/reset"
                            name="Password Reset Request Page"
                            render={props => (
                                <ErrorBoundary>
                                    <GuestComponent>
                                        <MaintenanceChecker>
                                            <PasswordResetEmail {...props} />
                                        </MaintenanceChecker>
                                    </GuestComponent>
                                </ErrorBoundary>
                            )}
                        />
                        {
                            external_four_zero_four === true
                                ? <Page404 />
                                : <Route path="/" name="Home" component={DefaultLayout} />
                        }
                    </Switch>
                    <ToastContainer
                        hideProgressBar
                        bodyClassName="color-black"
                    />
                </BrowserRouter>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { external_four_zero_four } = state.auth;

    return {
        external_four_zero_four,
    };
};

export default connect(
    mapStateToProps
)(App);
