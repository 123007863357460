import isNil from 'lodash/isNil';
import apiRequest from '../apiRequest';

export const find = async (id, token) =>
    apiRequest(`appointments/${id}`, {
        method: 'GET',
        token
    });

export const paginateOutstandingStarRatingAppointments = async ({token, page, pageSize, q, sort_name, sort_direction}) =>
    apiRequest('appointments/outstanding-star-ratings/paginate', {
        method: 'GET',
        token,
        params: {
            page,
            page_size: pageSize,
            q: !isNil(q) ? q : undefined,
            sort_name,
            sort_direction
        }
    });

export const rate = async ({ id, token, ...rest }) =>
    apiRequest(`appointments/${id}/rating`, {
        method: 'POST',
        token,
        data: rest
    });