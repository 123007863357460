import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { Container } from 'reactstrap';
import {
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav
} from '@coreui/react';
import ErrorBoundary from '../../ErrorBoundary';
import MaintenanceChecker from '../../MaintenanceChecker';
import LoggingOutCard from '../../components/Cards/LoggingOutCard';
import RestrictedComponent from '../../components/RestrictedComponent';
import DocumentsReadRestrictedComponent from '../../components/DocumentsReadRestrictedComponent';
// import UserNotApprovedRestrictedComponent from '../../components/UserNotApprovedRestrictedComponent';
import WaitingComponent from '../../components/WaitingComponent';
import { Page404 } from '../../views/Pages';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import navigation from '../../_nav';
import routes from '../../routes';
import Breadcrumbs from './Breadcrumbs';
import { Button } from '../../components';
import { ChoosePropertyModal } from '../../components/Modals/ChoosePropertyModal';

class DefaultLayout extends Component {
    state = {
        choosePropertyModalIsOpen: false,
    }

    render() {
        const {
            history,
            location,
            logging_out,
            user,
        } = this.props;
        
        const navConfig = cloneDeep(navigation);

        navConfig.items = navConfig.items.filter(navItem => {
            if (navItem.name === 'Viewing Packs' && !get(user, 'client.viewing_packs_count')) {
                return false;
            }

            if (navItem.name === 'Reapit' && !get(user, 'client.reapit_details')) {
                return false;
            }

            return true;
        });

        return (
            <React.Fragment>
                <div className="app">
                    <AppHeader fixed>
                        <DefaultHeader history={history} />
                    </AppHeader>
                    <div className="app-body">
                        <AppSidebar fixed display="lg">
                            <AppSidebarHeader />
                                <AppSidebarForm />
                                <AppSidebarNav
                                    location={location}
                                    navConfig={navConfig}
                                    staticContext={this.props.staticContext}
                                />
                                <AppSidebarFooter />
                            <AppSidebarMinimizer />
                        </AppSidebar>
                        <main className="main">
                            <Breadcrumbs
                                appRoutes={routes}
                                rightContent={
                                    <React.Fragment>
                                        <Button
                                            type='button'
                                            color='success'
                                            size='sm'
                                            onClick={() => this.setState({choosePropertyModalIsOpen: true})}
                                        >
                                            <i className='fa fa-plus' />
                                            <span className='ml-2'>Book appointment</span>
                                        </Button>
                                    </React.Fragment>
                                }
                            />
                            <Container fluid>
                                <LoggingOutCard loggingOut={logging_out} />
                                <Switch>
                                    {
                                        routes.map((route, idx) => {
                                            return route.component
                                                ? (
                                                    <Route
                                                        key={idx}
                                                        path={route.path}
                                                        exact={true}
                                                        name={route.name}
                                                        render={props => (
                                                            <ErrorBoundary>
                                                                <RestrictedComponent>
                                                                    <MaintenanceChecker>
                                                                        {/*<UserNotApprovedRestrictedComponent>*/}
                                                                            <DocumentsReadRestrictedComponent>
                                                                                <WaitingComponent
                                                                                    Component={route.component}
                                                                                    query={queryString.parse(location.search)}
                                                                                    {...props}
                                                                                />
                                                                            </DocumentsReadRestrictedComponent>
                                                                        {/*</UserNotApprovedRestrictedComponent>*/}
                                                                    </MaintenanceChecker>
                                                                </RestrictedComponent>
                                                            </ErrorBoundary>
                                                        )}
                                                    />
                                                )
                                                : null;
                                        })
                                    }
                                    <Route name="Page 404" component={Page404} />
                                </Switch>
                            </Container>
                        </main>
                    </div>
                    <AppFooter>
                        <DefaultFooter />
                    </AppFooter>
                </div>
                <ChoosePropertyModal
                    isOpen={this.state.choosePropertyModalIsOpen}
                    toggle={() => this.setState({choosePropertyModalIsOpen: !this.state.choosePropertyModalIsOpen})}
                    onBookNow={property => {
                        this.setState({choosePropertyModalIsOpen: false});
                        history.push(`/properties/${property.id}/appointments/create?skipPriceListStep=1`);
                    }}
                    onCreateNewProperty={() => {
                        this.setState({choosePropertyModalIsOpen: false});
                    }}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { logging_out, user } = state.auth;
    const { unreadNotificationCounts } = state.notifications;

    return {
        user,
        logging_out: logging_out === true,
        unreadNotificationCounts: unreadNotificationCounts,
    };
};

export default connect(
    mapStateToProps
)(DefaultLayout);
