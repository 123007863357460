import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getApiErrorMessages } from '../helpers/apiMessages';
import { getToReadResources as getToReadDocuments } from '../redux/documents/actions';

const {
    REACT_APP_TERMS_AND_CONDITIONS_DOCUMENT_CATEGORY_ID,
    REACT_APP_KEYHOLDER_DOCUMENT_CATEGORY_ID,
} = process.env;
// 15 minutes
const timeLimit = 15 * 60 * 1000;

class DocumentsReadRestrictedComponent extends Component {
    componentDidMount() {
        const {
            last_fetched,
            // token,
        } = this.props;
        const now = new Date();

        // Avoid fetching documents everytime
        // by storing last time the documents have been fetched
        // in global state
        // Once this limit has been reached (15 minutes)
        // Fetch again
        if(last_fetched + timeLimit < now.getTime()) {
            // const data = { token };

            // Get document to read
            // getToReadDocuments({ data });
        }
    }

    render() {
        const { location, to_read_documents } = this.props;
        const importantToReadDocuments = to_read_documents && to_read_documents.length
            ? to_read_documents.filter(document => {
                return document.category_id === parseInt(REACT_APP_TERMS_AND_CONDITIONS_DOCUMENT_CATEGORY_ID, 10)
                    || document.category_id === parseInt(REACT_APP_KEYHOLDER_DOCUMENT_CATEGORY_ID, 10);
            })
            : [];

        if(
            importantToReadDocuments
            && importantToReadDocuments.length > 0
            && location
            && location.pathname !== '/dashboard'
        ) {
            return <Redirect to="/dashboard" />;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    const { token } = state.auth;
    const {
        error,
        last_fetched,
        to_read_resources,
    } = state.documents;
    const errors = getApiErrorMessages(error);

    return {
        errors,
        last_fetched,
        to_read_documents: to_read_resources,
        token,
    };
};

const mapDispatchToProps = {
    getToReadDocuments,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DocumentsReadRestrictedComponent)
);
