import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as accessArrangementsAPI from '../../api/accessArrangements';

export function* getAllResourcesRequest() {
    yield takeEvery(actions.GET_ALL_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: accessArrangementsAPI.get,
            args: payload.data.token,
            onSuccess: actions.GET_ALL_RESOURCES_SUCCESS,
            onError: actions.GET_ALL_RESOURCES_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(getAllResourcesRequest),
    ]);
}
