import React, { Component } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { Redirect } from 'react-router-dom';

class RestrictedComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // We scroll to top at the mounting of restricted component as it seems
        // React leaves the scroll down if we have scrolled down to a previous page.
        // This means the scroll to top is going to be quick (1ms),
        // and the user shouldn't be noticing it
        scroll.scrollToTop({
            smooth: false,
            duration: 1,
        });
    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to="/login" />
        }

        return this.props.children;
    }
}

const mapStateToProps = state => ({
    loggedIn: (
        typeof state.auth.token !== 'undefined'
        && state.auth.token !== null
    )
});

export default connect(
    mapStateToProps
)(RestrictedComponent);
